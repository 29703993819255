.content-area{
    background-color: #FCFCFC; padding-bottom: 55px;
}

.single-property-content {
    background-color: #FFF;
    padding: 0px 15px 20px;
}

.prp-style-2 .single-property-wrapper{
    margin-top: -35px;
}

.prp-style-1 .single-property-wrapper{
    margin-top: -35px;
}

.single-property-header {
    padding-bottom: 25px;
    position: relative;
    clear: both;
    overflow: hidden;
}

.single-property-header .property-title {
    margin: 10px 0px;
    font-size: 28px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.single-property-header .property-price{
    color: #CBAA5C;
    font-size: 30px;
    letter-spacing: 1.5px;
    right: 0px;
    border-bottom: 2px solid;
    line-height: 42px;
}

.single-property-header .property-title {
    margin: 10px 0px;
    font-size: 28px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.single-property-header .property-price{
    color: #CBAA5C;
    font-size: 30px;
    letter-spacing: 1.5px;
    right: 0px;
    border-bottom: 2px solid;
    line-height: 42px;
}

.clearfix {
    clear: both;
}

.s-property-title {
    margin-bottom: 15px;
    color: #CBAA5C;
    font-size: 18px;
}

.s-property-title::after {
    background-color: #E5E6E7;
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    margin: 3px 0px 0px 2px;
}

.property-meta{
    padding: 20px 0px;
}

.property-info-entry .property-info-label {
    font-size: 13px;
    display: block;
    line-height: 15px;
    text-transform: uppercase;
}

.property-info-entry .property-info-value {
    font-size: 18px;    
    line-height: 24px;
    white-space: nowrap;
}

.property-info-entry .property-info-label {
    font-size: 13px;
    display: block;
    line-height: 15px;
    text-transform: uppercase;
}

.property-info-entry .property-info-value {
    font-size: 18px;    
    line-height: 24px;
    white-space: nowrap;
} 

.property-info-entry .property-info-label {
    font-size: 13px;
    display: block;
    line-height: 15px;
    text-transform: uppercase;
}

.property-info-entry .property-info-value {
    font-size: 18px;    
    line-height: 24px;
    white-space: nowrap;
}

.property-info-unit{
    font-size: 12px;    
    line-height: 12px;
    white-space: nowrap;
}

.section {
    padding-top: 15px;
    padding-bottom: 15px;
}

.s-property-title {
    margin-bottom: 15px;
    color: #CBAA5C;
    font-size: 18px;
}

.s-property-title::after {
    background-color: #E5E6E7;
    content: "";
    display: block;
    width: 30px;
    height: 3px;
    margin: 3px 0px 0px 2px;
}

.property-features ul {
    padding: 0px ;
    list-style: outside none none;
}

.property-features ul li{
    margin: 0px 15px 15px 0px;
    display: inline-table;
}

.property-features ul li a::after{
    content: "/";
    color: #CBAA5C;
    font-weight: bold;
    padding-left: 5px;
    font-size: 14px;
}

.property-features ul li a{
    text-transform: uppercase;
    color: #9C9C9C;
    font-weight: 500;
}

.property-features ul li a:hover{    
    color: black;
}

.au-btn {
    line-height: 45px;
    padding: 0 35px;
    text-transform: uppercase;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.au-btn:hover {
    color: #fff;
    background: #3868cd;
}

.au-btn--blue2 {
    background: #00aced;
}

.au-btn--blue2:hover {
    background: #00a2e3;
}

.au-btn--block {
    display: block;
    width: 100%;
	
}

.au-btn-icon i {
    vertical-align: baseline;
    margin-right: 5px;
}

.au-btn--blue {
    background: #DCAC38;
}

.au-btn--green {
    background:black;
}

.au-btn--green:hover {
    background:black;
}

.au-btn-plus {
    position: absolute;
    height: 45px;
    width: 45px;
    background: #000;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: -22.5px;
    right: 45px;
    z-index: 3;
}

.au-btn-plus i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.au-btn-plus:hover {
    background:  #808080;
}

.au-btn-load {
    background: #808080;
    padding: 0 40px;
    font-size: 15px;
    color: #fff;
}

.au-btn-load:hover {
    background: #767676;
}

.au-btn-filter {
    font-size: 14px;
    color: #808080;
    background: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
    padding: 0 15px;
    line-height: 40px;
    text-transform: capitalize;
}

.au-btn-filter i {
    margin-right: 5px;
}

.au-btn--small {
    padding: 0 20px;
    line-height: 40px;
    font-size: 14px;
}

.form-header2 .au-btn--submit {
    border: none;
    line-height: 45px;
}

.au-btn--submit {
    position: relative;
    right: 0;
    min-width: 65px;
    line-height: 43px;
    border: 1px solid#ffffff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #DCAC38;
    margin-left: -3px;
}

.au-btn--submit:hover {
    background: #3868cd;
}

.au-btn--submit > i {
    font-size: 20px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.au-btn--submit2 {
    height: 43px;
    width: 43px;
    position: absolute;
    top: 1px;
    right: 0;
}

.au-btn--submit2 i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #4c4c4c;
    font-size: 20px;
}

.img {
    width: 500px;
    height: 340px;
    object-fit: contain;
}