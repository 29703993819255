.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    padding: 24px 12px 64px;
    max-width: 720px;
    overflow-y: auto;
}
  
.item {
    display: flex;
    padding: 16px;
    position: relative;
    max-width: 25%;
    flex-basis: 100%;
    border-radius: 2px;
    flex-direction: column;
}
  
.inner-item {
    height: 212px;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
  
.SortableList {
    position: relative;
    z-index: 0;
    background-color: #F3F3F3;
    border: 1px solid #EFEFEF;
    border-radius: 3px;
    outline: none;  
    width: 400px;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
}

.SortableItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background-color: #FFF;
    border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
}

.SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}
