.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.removeScrollbar .fc-row {
  margin-right: 0 !important;
}
.removeScrollbar .fc-scroller {
  height: fit-content !important;
  overflow: hidden !important;
}
